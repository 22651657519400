import React, { useContext } from 'react';
import { LoadingContext } from './LoadingContext';
import './LoadingIndicator.css'; // Crie este arquivo para estilizar o indicador

function LoadingIndicator() {
  const { isLoading } = useContext(LoadingContext);

  if (!isLoading) return null;

  return (
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>
  );
}

export default LoadingIndicator;