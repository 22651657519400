// AlertasPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { io } from 'socket.io-client';
import './App.css';
import { LoadingContext } from './LoadingContext';

function AlertasPage() {
  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const alertsPerPage = 30;
  const [socket, setSocket] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const { startLoading, finishLoading } = useContext(LoadingContext);

  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  const [showPopup, setShowPopup] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [comment, setComment] = useState('');
  const [action, setAction] = useState('');
  const [resolved, setResolved] = useState(false);

  // Função para buscar dados do servidor com indicador de carregamento
  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Processar os dados para garantir que 'solved' é booleano
      const alertsData = response.data.map((alert) => ({
        ...alert,
        solved: alert.solved === true || alert.solved === 'true',
      }));
      setAlerts(alertsData);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const alertsData = response.data.map((alert) => ({
        ...alert,
        solved: alert.solved === true || alert.solved === 'true',
      }));
      setAlerts(alertsData);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Alertas:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações de alertas em tempo real
    newSocket.on('alertsUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de alerta recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [isFetching, socketURL]);

  const pageCount = Math.ceil(alerts.length / alertsPerPage);
  const currentAlerts = alerts.slice(
    currentPage * alertsPerPage,
    (currentPage + 1) * alertsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchDataWithoutLoading();
  };

  const handleCheckboxAction = (alert) => {
    setSelectedAlert(alert);
    setShowPopup(true);
  };

  const handleSave = async () => {
    if (selectedAlert) {
      try {
        const token = localStorage.getItem('token');
        await axios.put(
          `${baseURL}${apiPrefix}/alerts/${selectedAlert.alertID}`,
          {
            solved: resolved,
            action,
            comment,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Atualizar o alerta na lista
        setAlerts((prevAlerts) =>
          prevAlerts.map((alert) =>
            alert.alertID === selectedAlert.alertID
              ? { ...alert, action, comment, solved: resolved }
              : alert
          )
        );

        setShowPopup(false);
        setSelectedAlert(null);
      } catch (error) {
        console.error('Erro ao atualizar o alerta:', error);
      }
    }
  };

  useEffect(() => {
    if (selectedAlert) {
      setComment(selectedAlert.comment || '');
      setAction(selectedAlert.action || '');
      setResolved(
        selectedAlert.solved === true || selectedAlert.solved === 'true'
      );
    } else {
      // Resetar os campos quando nenhum alerta está selecionado
      setComment('');
      setAction('');
      setResolved(false);
    }
  }, [selectedAlert]);

  return (
    <div className="dashboard-content">
      {showPopup && selectedAlert && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Alerta</h2>

            <div className="modal-input-group">
              <div className="modal-input">
                <label>Paciente:</label>
                <input
                  type="text"
                  value={selectedAlert.patientName || ''}
                  readOnly
                />
              </div>
              <div className="modal-input">
                <label>Profissional:</label>
                <input
                  type="text"
                  value={selectedAlert.caregiverName || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="modal-input">
              <label>Motivo do Alerta:</label>
              <input
                type="text"
                value={selectedAlert.alertReasons?.join(', ') || ''}
                readOnly
              />
            </div>

            <h2>O que fazer?</h2>
            <div className="modal-input">
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                Marcar como resolvido
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={resolved}
                    onChange={(e) => setResolved(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>
              </label>
            </div>

            <div className="modal-input">
              <label>Ação</label>
              <select
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="discutir com time">Discutir com time</option>
                <option value="discutir com profissional">
                  Discutir com profissional
                </option>
                <option value="deixar observação">Deixar observação</option>
              </select>
            </div>

            <div className="modal-input">
              <label>Comentário</label>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Digite aqui"
              ></textarea>
            </div>

            <div className="modal-buttons">
              <button
                onClick={() => setShowPopup(false)}
                className="cancel-button"
              >
                Cancelar
              </button>
              <button onClick={handleSave} className="submit-button">
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="card large-card alertas-card">
        <h2 className="card-title">Todos os Alertas</h2>
        <div className="card-table alertas-table">
          <table>
            <thead>
              <tr>
                <th>Dia</th>
                <th>Horário</th>
                <th>Paciente</th>
                <th>Profissional</th>
                <th>Alerta</th>
                <th>Resolvido?</th>
              </tr>
            </thead>
            <tbody>
              {currentAlerts.map((alert) => (
                <tr key={alert.alertID}>
                  <td>{alert.day}</td>
                  <td>{alert.time}</td>
                  <td>{alert.patientName}</td>
                  <td>{alert.caregiverName}</td>
                  <td>
                    <ul>
                      {alert.alertReasons && alert.alertReasons.length > 0 ? (
                        alert.alertReasons.map((a, index) => (
                          <li key={index}>{a}</li>
                        ))
                      ) : (
                        <li>Nenhum alerta</li>
                      )}
                    </ul>
                  </td>
                  <td
                    className={`checkbox-container ${
                      alert.solved ? 'checked' : 'unchecked'
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={alert.solved}
                      onChange={() => handleCheckboxAction(alert)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>
    </div>
  );
}

export default AlertasPage;
