// Sidebar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css'; // Importação do CSS
import { FaHome, FaUserAlt, FaFileAlt, FaCalendarCheck, FaExclamationTriangle } from 'react-icons/fa';
import { CiViewList } from 'react-icons/ci';
import { MdOutlineMedicalInformation } from 'react-icons/md';
import logo from './logo.png'; // Supondo que você tenha um logo

function Sidebar({ userEmail }) {
  const isIsaUser = userEmail === 'admin@isa.com';
  const isSaniiUser = userEmail === 'admin@sanii.com';

  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        {isIsaUser && (
          <>
            <h2 className="sidebar-title">Internação Domiciliar</h2>
            <li>
              <NavLink to="/" exact="true" activeClassName="active">
                <FaHome className="sidebar-icon" /> Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/alertas" activeClassName="active">
                <FaExclamationTriangle className="sidebar-icon" /> Alertas
              </NavLink>
            </li>
            <li>
              <NavLink to="/sinaisvitais" activeClassName="active">
                <CiViewList  className="sidebar-icon" /> Sinais Vitais
              </NavLink>
            </li>
            <li>
              <NavLink to="/profissionais" activeClassName="active">
                <MdOutlineMedicalInformation className="sidebar-icon" /> Profissionais
              </NavLink>
            </li>
            <li>
              <NavLink to="/pacientes" activeClassName="active">
                <FaUserAlt className="sidebar-icon" /> Pacientes
              </NavLink>
            </li>
          </>
        )}

        {isSaniiUser && (
          <>
            <li className="sidebar-title">Sanii</li>
            <li>
              <NavLink to="/relatorios" activeClassName="active">
                <FaFileAlt className="sidebar-icon" /> Relatórios
              </NavLink>
            </li>
            <li>
              <NavLink to="/gestaodepresenca" activeClassName="active">
                <FaCalendarCheck className="sidebar-icon" /> Gestão de Presença
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
