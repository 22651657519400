// Navbar.js

import React from 'react';
import './Navbar.css';
import logo from './logo.png';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="navbar-right">
        <a href="#home">Home</a>
        <a href="#support">Suporte</a>
        <a href="#account">Minha Conta</a>
        <a onClick={handleLogoutClick} className="logout-button">
          Sair
        </a>
      </div>
    </div>
  );
};

export default Navbar;
