// CaregiverDetailsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import './CaregiverDetailsPage.css'; // Certifique-se de criar este arquivo para estilos específicos
import { LoadingContext } from './LoadingContext';

function CaregiverDetailsPage() {
  const { caregiverID } = useParams();
  const [caregiver, setCaregiver] = useState(null);
  const [activeTab, setActiveTab] = useState('pessoal'); // Estado para controlar a aba ativa
  const [socket, setSocket] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const { startLoading, finishLoading } = useContext(LoadingContext);

  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  // Função para buscar dados do servidor com indicador de carregamento
  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${baseURL}${apiPrefix}/caregivers/${caregiverID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCaregiver(response.data);
    } catch (error) {
      console.error('Erro ao buscar o cuidador:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${baseURL}${apiPrefix}/caregivers/${caregiverID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCaregiver(response.data);
    } catch (error) {
      console.error('Erro ao buscar o cuidador:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [caregiverID, startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Detalhes do Profissional:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações em tempo real do cuidador específico
    newSocket.on('caregiverUpdate', (updatedCaregiver) => {
      if (!isFetching && updatedCaregiver.caregiverID === caregiverID) {
        console.log('Atualização do profissional recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [caregiverID, isFetching, socketURL]);

  if (!caregiver) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="caregiver-details-container">
      <div className="caregiver-header">
        <h1>
          {caregiver.fullName} <span>{caregiver.phoneNumber}</span>
        </h1>
        <p>
          {caregiver.careType} ({caregiver.registration || 'Registro não informado'})
        </p>
        {/* Navegação entre as abas "Pessoal" e "Atendimentos" */}
        <div className="tabs">
          <span
            className={activeTab === 'pessoal' ? 'active' : ''}
            onClick={() => setActiveTab('pessoal')}
          >
            Pessoal
          </span>
          <span
            className={activeTab === 'atendimentos' ? 'active' : ''}
            onClick={() => setActiveTab('atendimentos')}
          >
            Atendimentos
          </span>
        </div>
      </div>

      {/* Exibição condicional com base na aba ativa */}
      {activeTab === 'pessoal' && (
        <div className="caregiver-info-section">
          <div className="form-group">
            <label>Sexo</label>
            <input type="text" value={caregiver.gender || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Data de Nascimento</label>
            <input type="text" value={caregiver.birthDate || ''} readOnly />
          </div>
          <div className="form-group">
            <label>CPF</label>
            <input type="text" value={caregiver.cpf || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Endereço</label>
            <input type="text" value={caregiver.address || ''} readOnly />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <input type="text" value={caregiver.email || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Celular</label>
            <input type="text" value={caregiver.phoneNumber || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Registro / Certificado</label>
            <input
              type="text"
              value={caregiver.registration || 'Registro não informado'}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Status do Certificado</label>
            <input
              type="text"
              value={caregiver.certificationStatus || 'Ativo'}
              readOnly
              className="active-status"
            />
          </div>
          <div className="form-group">
            <label>Experiência</label>
            <input type="text" value={caregiver.experience || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Especialidades</label>
            <input type="text" value={caregiver.specialties || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Bairros de Atuação</label>
            <input type="text" value={caregiver.areas || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Último Alerta</label>
            <input type="text" value={caregiver.lastAlert || ''} readOnly />
          </div>
        </div>
      )}

      {activeTab === 'atendimentos' && (
        <div className="caregiver-info-section">
          <div className="form-group">
            <label>Taxa de Presença</label>
            <input type="text" value={caregiver.presenceRate || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Taxa de Engajamento</label>
            <input type="text" value={caregiver.engagementRate || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Atendimentos Realizados no Mês</label>
            <input
              type="text"
              value={caregiver.monthlyCompletedAppointments || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Atendimentos Estimados no Mês</label>
            <input
              type="text"
              value={caregiver.estimatedMonthlyAppointments || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Último Atendimento</label>
            <input type="text" value={caregiver.lastAppointment || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Próximo Atendimento</label>
            <input type="text" value={caregiver.nextAppointment || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Pacientes Relacionados</label>
            <input type="text" value={caregiver.relatedPatients || ''} readOnly />
          </div>
          <div className="form-group">
            <label>Atendimentos Realizados Total</label>
            <input type="text" value={caregiver.totalAppointments || ''} readOnly />
          </div>
        </div>
      )}

      <div className="caregiver-cards">
        {/* Card de Calendário */}
        <div className="card calendar-card">
          <h3>Calendário de Plantões</h3>
          <div className="calendar">
            {/* Implementação real do calendário seria feita aqui */}
            {/* Exemplo de placeholder */}
            <p>Calendário não implementado.</p>
          </div>
        </div>

        {/* Card de Últimos Plantões */}
        <div className="card shifts-card">
          <h3>Últimos Plantões</h3>
          <table className="shifts-table">
            <thead>
              <tr>
                <th>Dia</th>
                <th>Horário</th>
                <th>Paciente</th>
                <th>Alerta</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {caregiver.shifts && caregiver.shifts.length > 0 ? (
                caregiver.shifts.map((shift, index) => (
                  <tr key={index}>
                    <td>{shift.day}</td>
                    <td>{shift.time}</td>
                    <td>{shift.patient}</td>
                    <td>{shift.alert ? 'Sim' : 'Não'}</td>
                    <td>
                      <a href="#">Ver</a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">Nenhum plantão recente</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CaregiverDetailsPage;
