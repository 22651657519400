// ProfissionaisPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { io } from 'socket.io-client';
import './App.css';
import { LoadingContext } from './LoadingContext';

function ProfissionaisPage() {
  const [caregivers, setCaregivers] = useState([]);
  const [patients, setPatients] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const caregiversPerPage = 10;
  const [socket, setSocket] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  // Estado para controlar a visibilidade do modal
  const [showModal, setShowModal] = useState(false);

  // Estado para os dados do novo cuidador
  const [newCaregiver, setNewCaregiver] = useState({
    fullName: '',
    careType: '',
    gender: '',
    phoneNumber: '',
    company: 'ISA',
  });

  const { startLoading, finishLoading } = useContext(LoadingContext);

  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  // Função para buscar dados do servidor com indicador de carregamento
  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/caregivers`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const caregiversFromISA = response.data.filter(
        (caregiver) => caregiver.company === 'ISA'
      );
      setCaregivers(caregiversFromISA);
    } catch (error) {
      console.error('Erro ao buscar os profissionais:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/caregivers`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const caregiversFromISA = response.data.filter(
        (caregiver) => caregiver.company === 'ISA'
      );
      setCaregivers(caregiversFromISA);
    } catch (error) {
      console.error('Erro ao buscar os profissionais:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Profissionais:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações em tempo real
    newSocket.on('caregiversUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de profissionais recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [isFetching, socketURL]);

  // Função para redirecionar para a página de detalhes
  const handleCaregiverClick = (caregiverID) => {
    navigate(`/caregiver/${caregiverID}`);
  };

  // Função para lidar com mudanças nos inputs do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCaregiver((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Função para lidar com o envio do formulário
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validação básica dos campos (pode ser aprimorada)
    if (
      !newCaregiver.fullName ||
      !newCaregiver.careType ||
      !newCaregiver.gender ||
      !newCaregiver.phoneNumber
    ) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Preparar os dados do cuidador
    const caregiverData = {
      ...newCaregiver,
    };

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${baseURL}${apiPrefix}/caregivers`,
        caregiverData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Atualizar a lista de cuidadores
      fetchDataWithoutLoading();

      // Fechar o modal e resetar o formulário
      setShowModal(false);
      setNewCaregiver({
        fullName: '',
        careType: '',
        gender: '',
        phoneNumber: '',
        company: 'ISA',
      });
    } catch (error) {
      console.error('Erro ao adicionar o profissional:', error);
      alert(
        'Ocorreu um erro ao adicionar o profissional. Por favor, tente novamente.'
      );
    }
  };

  // Obter o nome do paciente a partir do patientIdentifier
  const getPatientName = async (patientIdentifier) => {
    if (patients[patientIdentifier]) {
      return patients[patientIdentifier];
    } else {
      try {
        console.log(`Buscando paciente com ID: ${patientIdentifier}`);
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${baseURL}${apiPrefix}/patients/${encodeURIComponent(
            patientIdentifier
          )}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log('Resposta da API:', response.data);
        const fullName = response.data.fullName;
        setPatients((prev) => ({
          ...prev,
          [patientIdentifier]: fullName,
        }));
        return fullName;
      } catch (error) {
        console.error('Erro ao buscar o paciente:', error);
        return 'Desconhecido';
      }
    }
  };

  const processCaregiversData = async () => {
    const processedData = await Promise.all(
      caregivers.map(async (caregiver) => {
        const { workSchedulePoCArray } = caregiver;

        // Inicialização dos campos
        let ultimoPaciente = 'Nenhum';
        let ultimoPlantao = 'Nenhum';
        let proximoPaciente = 'Nenhum';
        let proximoPlantao = 'Nenhum';

        // Data atual no fuso horário de São Paulo
        const now = new Date();
        const nowInSaoPaulo = new Date(
          now.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' })
        );
        const today = new Date(
          nowInSaoPaulo.getFullYear(),
          nowInSaoPaulo.getMonth(),
          nowInSaoPaulo.getDate()
        );

        if (workSchedulePoCArray && workSchedulePoCArray.length > 0) {
          // Converter os dias para objetos Date
          const scheduleWithDates = workSchedulePoCArray.map((schedule) => {
            const [day, month, year] = schedule.day.split('/');
            // Definir a hora para meio-dia UTC
            const date = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
            return { ...schedule, date };
          });

          // Encontrar o Último Plantão
          const pastSchedules = scheduleWithDates
            .filter((s) => s.date <= today && s.started)
            .sort((a, b) => b.date - a.date);

          if (pastSchedules.length > 0) {
            const lastSchedule = pastSchedules[0];
            ultimoPaciente = await getPatientName(
              lastSchedule.patientIdentifier
            );
            ultimoPlantao = formatPlantaoDate(
              lastSchedule.date,
              lastSchedule.period
            );
          }

          // Encontrar o Próximo Plantão
          const futureSchedules = scheduleWithDates
            .filter((s) => s.date >= today && !s.started && !s.finished)
            .sort((a, b) => a.date - b.date);

          if (futureSchedules.length > 0) {
            const nextSchedule = futureSchedules[0];
            proximoPaciente = await getPatientName(
              nextSchedule.patientIdentifier
            );
            proximoPlantao = formatPlantaoDate(
              nextSchedule.date,
              nextSchedule.period
            );
          }
        }

        return {
          ...caregiver,
          ultimoPaciente,
          ultimoPlantao,
          proximoPaciente,
          proximoPlantao,
        };
      })
    );

    return processedData;
  };

  // Função para formatar a data do plantão
  const formatPlantaoDate = (dateObj, period) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'America/Sao_Paulo',
    };
    const dateStr = dateObj.toLocaleDateString('pt-BR', options);
    const timeStr = period === 'daytime' ? 'às 07h00' : 'às 19h00';
    return `${dateStr} ${timeStr}`;
  };

  // Dados processados com os campos necessários
  const [processedCaregivers, setProcessedCaregivers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const data = await processCaregiversData();
        setProcessedCaregivers(data);
      } catch (error) {
        console.error('Erro ao processar os dados dos profissionais:', error);
      } finally {
        finishLoading();
      }
    };
    if (caregivers.length > 0) {
      fetchData();
    }
  }, [caregivers, startLoading, finishLoading]);

  // Paginação
  const pageCount = Math.ceil(processedCaregivers.length / caregiversPerPage);
  const currentCaregivers = processedCaregivers.slice(
    currentPage * caregiversPerPage,
    (currentPage + 1) * caregiversPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchDataWithoutLoading();
  };

  return (
    <div className="dashboard-content">
      <div className="card large-card profissionais-card">
        <div className="card-header">
          <h2 className="card-title">Todos os Profissionais</h2>
          <button className="add-button" onClick={() => setShowModal(true)}>
            Adicionar
          </button>
        </div>
        <div className="card-table profissionais-table">
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo de Cuidado</th>
                <th>Sexo</th>
                <th>Último Paciente</th>
                <th>Último Plantão</th>
                <th>Próximo Paciente</th>
                <th>Próximo Plantão</th>
                <th>Celular</th>
              </tr>
            </thead>
            <tbody>
              {currentCaregivers.map((caregiver) => (
                <tr key={caregiver.caregiverID}>
                  <td
                    onClick={() =>
                      handleCaregiverClick(caregiver.caregiverID)
                    }
                    style={{ cursor: 'pointer', color: 'black' }}
                  >
                    {caregiver.fullName}
                  </td>
                  <td>{caregiver.careType}</td>
                  <td>{caregiver.gender}</td>
                  <td>{caregiver.ultimoPaciente}</td>
                  <td>{caregiver.ultimoPlantao}</td>
                  <td>{caregiver.proximoPaciente}</td>
                  <td>{caregiver.proximoPlantao}</td>
                  <td>{caregiver.phoneNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Adicionar Profissional</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label>Nome Completo:</label>
                <input
                  type="text"
                  name="fullName"
                  value={newCaregiver.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Tipo de Cuidado:</label>
                <input
                  type="text"
                  name="careType"
                  value={newCaregiver.careType}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Sexo:</label>
                <select
                  name="gender"
                  value={newCaregiver.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Selecione</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
              <div className="form-group">
                <label>Celular:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={newCaregiver.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-buttons">
                <button type="submit" className="submit-button">
                  Salvar
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfissionaisPage;
