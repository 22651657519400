// SinaisVitaisPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { io } from 'socket.io-client';
import { LoadingContext } from './LoadingContext';
import './App.css';

function SinaisVitaisPage() {
  const [vitalSigns, setVitalSigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const vitalSignsPerPage = 15;
  const [socket, setSocket] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const { startLoading, finishLoading } = useContext(LoadingContext);

  // Determina o ambiente (desenvolvimento ou produção)
  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Configura a baseURL e socketURL de acordo com o ambiente
  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  // Função para buscar dados do servidor com indicador de carregamento
  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/vitalsigns`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVitalSigns(response.data);
    } catch (error) {
      console.error('Erro ao buscar os sinais vitais:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/vitalsigns`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVitalSigns(response.data);
    } catch (error) {
      console.error('Erro ao buscar os sinais vitais:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Sinais Vitais:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações em tempo real
    newSocket.on('vitalsignsUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de sinais vitais recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [socketURL]); // Removido 'isFetching' das dependências

  // Paginação
  const pageCount = Math.ceil(vitalSigns.length / vitalSignsPerPage);
  const currentVitalSigns = vitalSigns.slice(
    currentPage * vitalSignsPerPage,
    (currentPage + 1) * vitalSignsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchDataWithoutLoading();
  };

  return (
    <div className="dashboard-content">
      <div className="card large-card vitais-card">
        <h2 className="card-title">Todos os Sinais Vitais</h2>
        <div className="card-table vitais-table">
          <table>
            <thead>
              <tr>
                <th>Dia</th>
                <th>Horário</th>
                <th>Paciente</th>
                <th>Profissional</th>
                <th>PAM</th>
                <th>Saturação O2</th>
                <th>Freq. Cardíaca</th>
                <th>Temperatura</th>
                <th>Freq. Respiratória</th>
                <th>AVPU</th>
                <th>O2 Suplementar</th>
                <th>Volume O2</th>
                <th>Concentrador</th>
                <th>Sintomas AR</th>
                <th>Sintomas TU</th>
                <th>Score</th>
                <th>Risco</th>
                <th>Alerta</th>
              </tr>
            </thead>
            <tbody>
              {currentVitalSigns.map((sign) => (
                <tr key={sign.vitalSignsID}>
                  <td>{sign.diaAfericao}</td>
                  <td>{sign.horarioAfericao}</td>
                  <td>{sign.patientName}</td>
                  <td>{sign.caregiverName}</td>
                  <td>{sign.PAM}</td>
                  <td>{sign.saturationO2}</td>
                  <td>{sign.heartRate}</td>
                  <td>{sign.temperature}</td>
                  <td>{sign.respRate}</td>
                  <td>{sign.consciousness}</td>
                  <td>{sign.supplementaryOxygen}</td>
                  <td>{sign.oxygenVolume}</td>
                  <td>{sign.concentrador}</td>
                  <td>{sign.ARSymptom}</td>
                  <td>{sign.TUSymptom}</td>
                  <td className={`risco-${sign.risco?.toLowerCase()}`}>{sign.score}</td>
                  <td className={`risco-${sign.risco?.toLowerCase()}`}>{sign.risco}</td>
                  <td>{sign.Alerta}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>
    </div>
  );
}

export default SinaisVitaisPage;
