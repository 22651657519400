// RelatoriosPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { LoadingContext } from './LoadingContext';
import { io } from 'socket.io-client'; // Importação do socket.io-client
import './App.css';

function RelatoriosPage() {
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const reportsPerPage = 20;
  const [socket, setSocket] = useState(null); // Estado para armazenar o socket
  const [isFetching, setIsFetching] = useState(false); // Estado para controlar atualizações simultâneas

  const { startLoading, finishLoading } = useContext(LoadingContext);
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Determina o ambiente (desenvolvimento ou produção)
  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Configura a baseURL e socketURL de acordo com o ambiente
  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/relatorios`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReports(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true); // Marca como fetching para evitar duplicações
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/relatorios`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setReports(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsFetching(false); // Libera para futuras atualizações após a resposta
    }
  };

  useEffect(() => {
    fetchData(); // Carrega os dados iniciais com loading
  }, [startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: {
        token: token,
      },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Relatórios:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações de relatórios em tempo real
    newSocket.on('relatoriosUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de relatório recebida. Atualizando dados...');
        fetchDataWithoutLoading(); // Atualiza os dados sem mostrar o loading
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [socketURL]); // Removemos 'isFetching' das dependências

  const pageCount = Math.ceil(reports.length / reportsPerPage);
  const currentReports = reports.slice(
    currentPage * reportsPerPage,
    (currentPage + 1) * reportsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchData(); // Usa fetchData com loading ao mudar de página
  };

  const formatarETruncarNotas = (notasArray) => {
    if (!Array.isArray(notasArray) || notasArray.length === 0) return 'Sem notas';
  
    const firstNote = notasArray[0];
  
    let notasExibidas;
    if (notasArray.length > 1) {
      notasExibidas = (
        <>
          {firstNote}
          <br />
          <span
            className="ver-mais-link"
            onClick={() => {
              setModalContent(
                notasArray.map((nota, index) => (
                  <div key={index}>
                    {nota}
                    <br />
                  </div>
                ))
              );
              setIsModalOpen(true);
            }}
          >
            Ver mais
          </span>
        </>
      );
    } else {
      notasExibidas = firstNote;
    }
  
    return notasExibidas;
  };

  return (
    <div className="dashboard-content">
      <div className="card large-card alertas-card">
        <h2 className="card-title">Relatórios</h2>
        <div className="card-table alertas-table">
          <table>
            <thead>
              <tr>
                <th>ID Conversa</th>
                <th>Data Criação</th>
                <th>Nome Profissional</th>
                <th>Nome Paciente</th>
                <th>Notas</th>
                <th>Hidratação/Alimentação</th>
                <th>Evacuações/Diurese</th>
                <th>Sono</th>
                <th>Humor</th>
                <th>Informações Adicionais</th>
                <th>Avaliação Nota</th>
                <th>Avaliação Descrição</th>
              </tr>
            </thead>
            <tbody>
              {currentReports.map((report, index) => (
                <tr key={index}>
                  <td>{report.id_conversa}</td>
                  <td>{report.data_criacao}</td>
                  <td>{report.nome_profissional}</td>
                  <td>{report.nome_paciente}</td>
                  <td>{formatarETruncarNotas(report.notas)}</td>
                  <td>{truncateText(report.hidratacao_alimentacao)}</td>
                  <td>{truncateText(report.evacuacoes_diurese)}</td>
                  <td>{truncateText(report.sono)}</td>
                  <td>{truncateText(report.humor)}</td>
                  <td>{truncateText(report.informacoes_adicionais)}</td>
                  <td>{report.avaliacao_nota}</td>
                  <td>{truncateText(report.avaliacao_descricao)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-report">
            <div>{modalContent}</div>
            <button
              onClick={() => {
                setIsModalOpen(false);
                setModalContent('');
              }}
              className="modal-close-button"
            >
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );

  function truncateText(text) {
    if (!text) return '';
    if (text.length > 120) {
      return (
        <>
          {text.substring(0, 120)}...
          <span
            className="ver-mais-link"
            onClick={() => {
              setModalContent(text);
              setIsModalOpen(true);
            }}
          >
            Ver mais
          </span>
        </>
      );
    }
    return text;
  }
}

export default RelatoriosPage;
