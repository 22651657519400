// GestaoPresencaPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { LoadingContext } from './LoadingContext';
import { io } from 'socket.io-client'; // Importação do socket.io-client
import './App.css';

function GestaoPresencaPage() {
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const recordsPerPage = 20; 
  const [socket, setSocket] = useState(null); // Estado para armazenar o socket
  const [isFetching, setIsFetching] = useState(false); // Estado para controlar atualizações simultâneas

  const { startLoading, finishLoading } = useContext(LoadingContext);

  // Função fetchData para buscar dados do servidor com loading
  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Configura a baseURL e socketURL de acordo com o ambiente
  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  // Ajusta o prefixo da API conforme o ambiente
  const apiPrefix = isDevelopment ? '' : '/api';

  // Função fetchData para buscar dados do servidor com loading
  const fetchData = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${baseURL}${apiPrefix}/gestaodepresenca`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRecords(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      finishLoading();
    }
  };

  // Função fetchDataWithoutLoading para atualizar os dados sem mostrar o indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true); // Marca como fetching para evitar duplicações
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${baseURL}${apiPrefix}/gestaodepresenca`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRecords(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsFetching(false); // Libera para futuras atualizações após a resposta
    }
  };

  useEffect(() => {
    fetchData(); // Carrega os dados iniciais com loading
  }, [startLoading, finishLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io('https://www.quorumsaude.com.br', {
      path: '/socket.io',
      auth: {
        token: token,
      },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Gestão de Presença:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações de presença em tempo real
    newSocket.on('gestaodepresencaUpdate', () => {
      if (!isFetching) { // Verifica se não há atualização em progresso
        console.log('Atualização de presença recebida. Atualizando dados...');
        fetchDataWithoutLoading(); // Atualiza os dados sem mostrar o loading
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [isFetching]);

  const pageCount = Math.ceil(records.length / recordsPerPage);
  const currentRecords = records.slice(
    currentPage * recordsPerPage,
    (currentPage + 1) * recordsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchData(); // Usa fetchData com loading ao mudar de página
  };

  return (
    <div className="dashboard-content">
      <div className="card large-card alertas-card">
        <h2 className="card-title">Gestão de Presença</h2>
        <div className="card-table alertas-table">
          <table>
            <thead>
              <tr>
                <th>Nome Profissional</th>
                <th>Nome Paciente</th>
                <th>Status Plantão</th>
                <th>Data Plantão</th>
                <th>Turno Plantão</th>
                <th>Primeiro Lembrete</th>
                <th>Segundo Lembrete</th>
                <th>Terceiro Lembrete</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((rec, index) => (
                <tr key={index}>
                  <td>{rec.nome_profissional}</td>
                  <td>{rec.nome_paciente}</td>
                  <td
                    style={{
                      color:
                        rec.status_plantao === 'aguardando resposta'
                          ? 'orange'
                          : rec.status_plantao === 'confirmado'
                          ? 'green'
                          : rec.status_plantao === 'cancelado'
                          ? 'red'
                          : 'black',
                      fontWeight: rec.status_plantao === 'cancelado' ? 'bold' : 'normal',
                    }}
                  >
                    {rec.status_plantao}
                  </td>
                  <td>{rec.data_plantao}</td>
                  <td>{rec.turno_plantao}</td>
                  <td>{rec.primeiro_lembrete}</td>
                  <td>{rec.segundo_lembrete}</td>
                  <td>{rec.terceiro_lembrete}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Componente de paginação */}
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>
    </div>
  );
}

export default GestaoPresencaPage;
