// PacientesPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { io } from 'socket.io-client';
import { LoadingContext } from './LoadingContext';
import './App.css';

function PacientesPage() {
  const [patients, setPatients] = useState([]);
  const [vitalSignsMap, setVitalSignsMap] = useState({});
  const [alertsMap, setAlertsMap] = useState({});
  const [caregiversSchedules, setCaregiversSchedules] = useState([]);
  const [processedPatients, setProcessedPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const patientsPerPage = 10;
  const [socket, setSocket] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const { startLoading, finishLoading } = useContext(LoadingContext);

  // Determina o ambiente (desenvolvimento ou produção)
  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Configura a baseURL e socketURL de acordo com o ambiente
  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  const apiPrefix = isDevelopment ? '' : '/api';

  // Estado para controlar a visibilidade do modal
  const [showModal, setShowModal] = useState(false);

  // Estado para os dados do novo paciente
  const [newPatient, setNewPatient] = useState({
    fullName: '',
    age: '',
    gender: '',
    chronicConditions: '',
    healthPlan: '',
    devices: '',
    particularities: '',
    company: 'ISA', // Assumindo que o paciente é da empresa ISA
  });

  // Função para buscar dados dos pacientes com indicador de carregamento
  const fetchPatients = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/patients`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const patientsFromISA = response.data.filter(
        (patient) => patient.company === 'ISA'
      );
      setPatients(patientsFromISA);
    } catch (error) {
      console.error('Erro ao buscar os pacientes:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para buscar dados dos sinais vitais com indicador de carregamento
  const fetchVitalSigns = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/vitalsigns`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const vitalSigns = response.data;
      const vitalSignsByPatient = {};

      vitalSigns.forEach((vs) => {
        const patientID = vs.patientID;
        if (
          !vitalSignsByPatient[patientID] ||
          new Date(vs.diaAfericao + 'T' + vs.horarioAfericao) >
            new Date(
              vitalSignsByPatient[patientID].diaAfericao +
                'T' +
                vitalSignsByPatient[patientID].horarioAfericao
            )
        ) {
          vitalSignsByPatient[patientID] = vs;
        }
      });

      setVitalSignsMap(vitalSignsByPatient);
    } catch (error) {
      console.error('Erro ao buscar os sinais vitais:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para buscar dados dos alertas com indicador de carregamento
  const fetchAlerts = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/alerts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const alerts = response.data;
      const alertsByPatient = {};

      const now = new Date();
      const timezone = 'America/Sao_Paulo';
      const time48HoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000);

      alerts.forEach((alert) => {
        const alertDateTime = new Date(
          `${alert.day}T${alert.time}`
        ).toLocaleString('en-US', { timeZone: timezone });
        const alertDate = new Date(alertDateTime);

        if (alertDate >= time48HoursAgo && alert.patientID) {
          const patientID = alert.patientID;
          const alertCount = alert.alerts ? alert.alerts.length : 0;
          alertsByPatient[patientID] =
            (alertsByPatient[patientID] || 0) + alertCount;
        }
      });

      setAlertsMap(alertsByPatient);
    } catch (error) {
      console.error('Erro ao buscar os alertas:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para buscar dados dos cuidadores com indicador de carregamento
  const fetchCaregiversSchedules = async () => {
    startLoading();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${baseURL}${apiPrefix}/caregivers`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const caregivers = response.data;
      const schedules = [];

      caregivers.forEach((caregiver) => {
        const { workSchedulePoCArray, fullName } = caregiver;
        if (workSchedulePoCArray) {
          workSchedulePoCArray.forEach((schedule) => {
            schedules.push({
              ...schedule,
              caregiverName: fullName,
            });
          });
        }
      });

      setCaregiversSchedules(schedules);
    } catch (error) {
      console.error('Erro ao buscar os profissionais:', error);
    } finally {
      finishLoading();
    }
  };

  // Função para atualizar os dados sem indicador de carregamento
  const fetchDataWithoutLoading = async () => {
    setIsFetching(true);
    try {
      await Promise.all([
        fetchPatients(),
        fetchVitalSigns(),
        fetchAlerts(),
        fetchCaregiversSchedules(),
      ]);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchDataWithoutLoading();
  }, []);

  // Configuração do WebSocket
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor WebSocket
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: { token },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao WebSocket para Pacientes:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão ao WebSocket:', err.message);
    });

    // Listener para atualizações em tempo real
    newSocket.on('patientsUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de pacientes recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    newSocket.on('vitalsignsUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de sinais vitais recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    newSocket.on('alertsUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de alertas recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    newSocket.on('caregiversUpdate', () => {
      if (!isFetching) {
        console.log('Atualização de profissionais recebida. Atualizando dados...');
        fetchDataWithoutLoading();
      }
    });

    // Desconectar o WebSocket ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, [socketURL]); // Removido 'isFetching' das dependências

  useEffect(() => {
    const processPatientsData = async () => {
      const processedData = await Promise.all(
        patients.map(async (patient) => {
          const patientID = patient.patientID;

          // Obter o score mais recente
          const vitalSign = vitalSignsMap[patientID];
          const recentScore = vitalSign ? vitalSign.score : 'N/A';
          const alertsCount = alertsMap[patientID] || 0;
          const { ultimoProfissional, proximoProfissional } =
            getPatientCaregivers(patientID);

          return {
            ...patient,
            recentScore,
            alertsCount,
            ultimoProfissional,
            proximoProfissional,
          };
        })
      );

      setProcessedPatients(processedData);
    };

    if (patients.length > 0) {
      processPatientsData();
    }
  }, [patients, vitalSignsMap, alertsMap, caregiversSchedules]);

  const getPatientCaregivers = (patientID) => {
    const timezone = 'America/Sao_Paulo';
    const now = new Date();
    const nowInSaoPaulo = new Date(
      now.toLocaleString('en-US', { timeZone: timezone })
    );
    nowInSaoPaulo.setHours(0, 0, 0, 0);

    const schedules = caregiversSchedules
      .filter((schedule) => schedule.patientIdentifier === patientID)
      .map((schedule) => {
        const [day, month, year] = schedule.day.split('/');
        const date = new Date(
          year,
          month - 1,
          day,
          schedule.period === 'daytime' ? 7 : 19,
          0,
          0
        );
        return { ...schedule, date };
      });

    const pastSchedules = schedules
      .filter((s) => s.date <= nowInSaoPaulo && s.started)
      .sort((a, b) => b.date - a.date);

    const futureSchedules = schedules
      .filter((s) => s.date >= nowInSaoPaulo && !s.started && !s.finished)
      .sort((a, b) => a.date - b.date);

    const ultimoProfissional =
      pastSchedules.length > 0 ? pastSchedules[0].caregiverName : 'Nenhum';

    const proximoProfissional =
      futureSchedules.length > 0 ? futureSchedules[0].caregiverName : 'Nenhum';

    return { ultimoProfissional, proximoProfissional };
  };

  // Paginação
  const pageCount = Math.ceil(processedPatients.length / patientsPerPage);
  const currentPatients = processedPatients.slice(
    currentPage * patientsPerPage,
    (currentPage + 1) * patientsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchDataWithoutLoading();
  };

  // Função para lidar com mudanças nos inputs do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPatient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Função para lidar com o envio do formulário
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validação básica dos campos (pode ser aprimorada)
    if (!newPatient.fullName || !newPatient.age || !newPatient.gender) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Convertendo strings separadas por ';' em arrays
    const chronicConditionsArray = newPatient.chronicConditions
      ? newPatient.chronicConditions.split(';').map((item) => item.trim())
      : [];
    const devicesArray = newPatient.devices
      ? newPatient.devices.split(';').map((item) => item.trim())
      : [];
    const particularitiesArray = newPatient.particularities
      ? newPatient.particularities.split(';').map((item) => item.trim())
      : [];

    // Preparar os dados do paciente
    const patientData = {
      ...newPatient,
      chronicConditions: chronicConditionsArray,
      devices: devicesArray,
      particularities: particularitiesArray,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${baseURL}${apiPrefix}/patients`,
        patientData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Atualizar a lista de pacientes com o novo paciente
      setPatients((prevPatients) => [...prevPatients, response.data]);

      // Fechar o modal e resetar o formulário
      setShowModal(false);
      setNewPatient({
        fullName: '',
        age: '',
        gender: '',
        chronicConditions: '',
        healthPlan: '',
        devices: '',
        particularities: '',
        company: 'ISA',
      });
    } catch (error) {
      console.error('Erro ao adicionar o paciente:', error);
      alert(
        'Ocorreu um erro ao adicionar o paciente. Por favor, tente novamente.'
      );
    }
  };

  return (
    <div className="dashboard-content">
      <div className="card large-card pacientes-card">
        <div className="card-header">
          <h2 className="card-title">Todos os Pacientes</h2>
          <button className="add-button" onClick={() => setShowModal(true)}>
            Adicionar
          </button>
        </div>
        <div className="card-table pacientes-table">
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Idade</th>
                <th>Sexo</th>
                <th>Condições Crônicas</th>
                <th>Plano de Saúde</th>
                <th>Dispositivos</th>
                <th>Particularidades</th>
                <th>Score Mais Recente</th>
                <th>Alertas nas Últimas 48h</th>
                <th>Profissional Último Plantão</th>
                <th>Profissional Próx. Plantão</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map((patient) => (
                <tr key={patient.patientID}>
                  <td>{patient.fullName}</td>
                  <td>{patient.age}</td>
                  <td>{patient.gender}</td>
                  <td>
                    {patient.chronicConditions
                      ? patient.chronicConditions.join('; ')
                      : 'N/A'}
                  </td>
                  <td>{patient.healthPlan}</td>
                  <td>{patient.devices ? patient.devices.join('; ') : 'N/A'}</td>
                  <td>
                    {patient.particularities
                      ? patient.particularities.join('; ')
                      : 'N/A'}
                  </td>
                  <td>{patient.recentScore}</td>
                  <td>{patient.alertsCount}</td>
                  <td>{patient.ultimoProfissional}</td>
                  <td>{patient.proximoProfissional}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Componente de paginação */}
          <ReactPaginate
            previousLabel={'Anterior'}
            nextLabel={'Próximo'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Adicionar Paciente</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label>Nome Completo:</label>
                <input
                  type="text"
                  name="fullName"
                  value={newPatient.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Idade:</label>
                <input
                  type="number"
                  name="age"
                  value={newPatient.age}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Sexo:</label>
                <select
                  name="gender"
                  value={newPatient.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Selecione</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
              <div className="form-group">
                <label>Condições Crônicas (separadas por ';'):</label>
                <input
                  type="text"
                  name="chronicConditions"
                  value={newPatient.chronicConditions}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Plano de Saúde:</label>
                <input
                  type="text"
                  name="healthPlan"
                  value={newPatient.healthPlan}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Dispositivos (separados por ';'):</label>
                <input
                  type="text"
                  name="devices"
                  value={newPatient.devices}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Particularidades (separadas por ';'):</label>
                <input
                  type="text"
                  name="particularities"
                  value={newPatient.particularities}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-buttons">
                <button type="submit" className="submit-button">
                  Salvar
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default PacientesPage;
