// App.js

import React, { useState, useEffect } from 'react';
import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Importação correta
import LoadingIndicator from './LoadingIndicator';
import { LoadingProvider } from './LoadingContext';
import axios from 'axios';
import { io } from 'socket.io-client'; // Importar o socket.io-client
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Login from './Login';
import AlertasPage from './AlertasPage';
import SinaisVitaisPage from './SinaisVitaisPage';
import ProfissionaisPage from './ProfissionaisPage';
import PacientesPage from './PacientesPage';
import CaregiverDetailsPage from './CaregiverDetailsPage';
import RelatoriosPage from './RelatoriosPage';
import GestaoDePresencaPage from './GestaoPresencaPage';
import Plot from 'react-plotly.js';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AiFillAlert } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa6';
import { MdChecklist } from 'react-icons/md';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('token')
  );
  const [sessionExpired, setSessionExpired] = useState(false);
  const [userEmail, setUserEmail] = useState(null); // Novo estado para armazenar o email do usuário
  const [vitalSigns, setVitalSigns] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [vitalSignsCountData, setVitalSignsCountData] = useState([]);
  const [socket, setSocket] = useState(null); // Estado para o socket
  // Estados adicionais para pacientes e cuidadores
  const [patients, setPatients] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [checkedAlerts, setCheckedAlerts] = useState({});

  const navigate = useNavigate();
  const location = useLocation(); // Obtém a rota atual

  // Determina o ambiente (desenvolvimento ou produção)
  const isDevelopment =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  // Configura a baseURL e socketURL de acordo com o ambiente
  const baseURL = isDevelopment ? 'http://localhost:8080' : '';
  const socketURL = isDevelopment
    ? 'http://localhost:8080'
    : 'https://www.quorumsaude.com.br';

  // Ajusta o prefixo da API conforme o ambiente
  const apiPrefix = isDevelopment ? '' : '/api';


  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    if (socket) {
      socket.disconnect(); // Desconectar o socket ao fazer logout
    }
    navigate('/login');
  };

  const handleSessionExpiration = () => {
    setSessionExpired(true);
  };

  const handleModalClose = () => {
    setSessionExpired(false);
    handleLogout(); // Realiza o logout efetivo
  };

  // Configure session expiration timer e obtenha o email do usuário
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const decodedToken = jwtDecode(token);
      setUserEmail(decodedToken.email);

      const expirationTime = decodedToken.exp * 1000; // Converte para milissegundos
      const currentTime = Date.now();
      const remainingTime = expirationTime - currentTime;

      if (remainingTime <= 0) {
        handleSessionExpiration();
      } else {
        const timeoutId = setTimeout(() => {
          handleSessionExpiration();
        }, remainingTime);

        return () => clearTimeout(timeoutId);
      }
    } catch (error) {
      console.error('Erro ao decodificar o token JWT:', error);
      handleSessionExpiration();
    }
  }, [isAuthenticated]);

  // Configure Axios interceptor
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          handleSessionExpiration();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Conexão com o WebSocket e configuração dos listeners
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Conectar ao servidor Socket.IO
    const newSocket = io(socketURL, {
      path: '/socket.io',
      auth: {
        token: token,
      },
      transports: ['websocket'],
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Conectado ao servidor WebSocket:', newSocket.id);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Erro de conexão:', err.message);
    });

    // Ouvir por atualizações de sinais vitais
    newSocket.on('vitalsignsUpdate', (data) => {
      console.log('Atualização de sinais vitais recebida via WebSocket');
      setVitalSigns(Array.isArray(data) ? data : []);
    });

    // Ouvir por atualizações de vitalsignsCount
    newSocket.on('vitalsignsCountUpdate', (data) => {
      console.log('Atualização de vitalsigns count recebida via WebSocket');
      setVitalSignsCountData(Array.isArray(data) ? data : []);
    });

    // Ouvir por atualizações de relatórios
    newSocket.on('reportsUpdate', (data) => {
      console.log('Atualização de plantões recebido via WebSocket');
      setReportData(Array.isArray(data) ? data : []);
    });

    // Limpar o socket ao desmontar
    return () => {
      newSocket.disconnect();
    };
  }, [isAuthenticated, socketURL]);

  // Data fetching
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;

    // Carrega dados específicos para o usuário ISA
    if (userEmail === 'admin@isa.com') {
      axios
        .get(`${baseURL}${apiPrefix}/vitalsigns`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          setVitalSigns(Array.isArray(data) ? data : []);
        })
        .catch((error) => console.error('Erro ao buscar os dados:', error));

      axios
        .get(`${baseURL}${apiPrefix}/alerts`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          const unsolvedAlerts = (Array.isArray(data) ? data : []).filter(
            (alert) => {
              const solved = alert.solved === true || alert.solved === 'true';
              return !solved;
            }
          );
          setAlerts(unsolvedAlerts);
        })
        .catch((error) => console.error('Erro ao buscar os alertas:', error));

      axios
        .get(`${baseURL}${apiPrefix}/vitalsigns-count`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          setVitalSignsCountData(Array.isArray(data) ? data : []);
        })
        .catch((error) =>
          console.error('Erro ao buscar os dados dos sinais vitais:', error)
        );

      axios
        .get(`${baseURL}${apiPrefix}/reports-pocuser`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          setReportData(Array.isArray(data) ? data : []);
        })
        .catch((error) =>
          console.error('Erro ao buscar os dados dos relatórios:', error)
        );

      // Carrega pacientes e cuidadores para atualizações via WebSocket
      axios
        .get(`${baseURL}${apiPrefix}/patients`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response.data;
          setPatients(Array.isArray(data) ? data : []);
        })
        .catch((error) => console.error('Erro ao buscar pacientes:', error));

      
    }
  }, [isAuthenticated, userEmail, baseURL, apiPrefix]);

  const handleCheckboxAction = (alert) => {
    setSelectedAlert(alert);
    setShowPopup(true);
  };

  const handleSave = async (comment, action, resolved) => {
    if (selectedAlert) {
      try {
        const token = localStorage.getItem('token');
        console.log('Sending solved:', resolved, 'type:', typeof resolved);
        await axios.put(
          `${baseURL}${apiPrefix}/alerts/${selectedAlert.alertID}`,
          {
            solved: resolved,
            action,
            comment,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        if (resolved) {
          setAlerts((prevAlerts) =>
            prevAlerts.filter(
              (alert) => alert.alertID !== selectedAlert.alertID
            )
          );
        } else {
          // Atualizar o alerta na lista
          setAlerts((prevAlerts) =>
            prevAlerts.map((alert) =>
              alert.alertID === selectedAlert.alertID
                ? { ...alert, action, comment }
                : alert
            )
          );
        }
  
        setShowPopup(false);
        setSelectedAlert(null);
      } catch (error) {
        console.error('Erro ao atualizar o alerta:', error);
      }
    }
  };
  
  const vitalSignsToDisplay = Array.isArray(vitalSigns) ? vitalSigns.slice(0, 10) : [];
  const alertsToDisplay = Array.isArray(alerts) ? alerts.slice(0, 10) : [];

  const riscoCount = (Array.isArray(vitalSigns) ? vitalSigns : []).reduce((acc, sign) => {
    acc[sign.risco] = (acc[sign.risco] || 0) + 1;
    return acc;
  }, {});

  const totalPacientes = Array.isArray(vitalSigns) ? vitalSigns.length : 0;
  const riscoLabels = Object.keys(riscoCount);
  const riscoValues = Object.values(riscoCount);
  const porcentagemCriticoRisco =
    totalPacientes > 0
      ? ((riscoCount['Critico'] || 0) / totalPacientes) * 100
      : 0;

  const totalAlerts = alertsToDisplay.length;
  const percentageRead = 0;

  const handleCheckboxChange = (alertID) => {
    setCheckedAlerts((prev) => ({
      ...prev,
      [alertID]: !prev[alertID],
    }));
  };

  // Data for "Plantões Iniciados Hoje" chart
  const dates = Array.isArray(reportData) ? reportData.map((report) => report.date) : [];
  const counts = Array.isArray(reportData) ? reportData.map((report) => report.count) : [];
  const latestCount = counts.length > 0 ? counts[counts.length - 1] : 0;

  // Expected number of measurements per day
  const expectedMeasurementsPerDay = 5;

  // Data for "Aferições de Sinais Vitais" chart
  const vitalSignsDates = Array.isArray(vitalSignsCountData) ? vitalSignsCountData.map((item) => item.date) : [];
  const vitalSignsCounts = Array.isArray(vitalSignsCountData) ? vitalSignsCountData.map((item) => item.count) : [];

  // Percentages of measurements taken
  const vitalSignsPercentages = vitalSignsCounts.map(
    (count) => (count / expectedMeasurementsPerDay) * 100
  );

  const latestVitalSignsPercentage =
    vitalSignsPercentages.length > 0
      ? vitalSignsPercentages[vitalSignsPercentages.length - 1]
      : 0;

  // Protected route component
  function RequireAuth({ children }) {
    const token = localStorage.getItem('token');
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
  }

  // Verifica se a rota atual é a de login
  const isLoginPage = location.pathname === '/login';

  const isIsaUser = userEmail === 'admin@isa.com';
  const isSaniiUser = userEmail === 'admin@sanii.com';

  return (
    <LoadingProvider>
      <LoadingIndicator />
      {/* Exibe o pop-up apenas se a sessão expirou e não está na página de login */}
      {sessionExpired && !isLoginPage && (
        <div className="modal-overlay-expire">
          <div className="modal-expire">
            <h2>Sessão Expirada</h2>
            <p>Sua sessão expirou. Por favor, faça login novamente.</p>
            <button
              onClick={handleModalClose}
              className="submit-button-expire"
            >
              OK
            </button>
          </div>
        </div>
      )}
      
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <div className="app-container">
                <Sidebar userEmail={userEmail} /> {/* Passamos userEmail para o Sidebar */}
                <div className="content">
                  <Navbar onLogout={handleLogout} />
                  <Routes>
                    {/* Redirecionamento da rota "/" com base no usuário */}
                    <Route
                      path="/"
                      element={
                        isSaniiUser ? (
                          <Navigate to="/relatorios" replace />
                        ) : isIsaUser ? (
                          <DashboardContent
                            
                            handleSave={handleSave}
                            selectedAlert={selectedAlert}
                            showPopup={showPopup}
                            setShowPopup={setShowPopup}
                            handleCheckboxAction={handleCheckboxAction}
                            checkedAlerts={checkedAlerts}
                            
                            
                            vitalSigns={vitalSigns}
                            vitalSignsToDisplay={vitalSignsToDisplay}
                            alerts={alerts}
                            alertsToDisplay={alertsToDisplay}
                            riscoLabels={riscoLabels}
                            riscoValues={riscoValues}
                            porcentagemCriticoRisco={
                              porcentagemCriticoRisco
                            }
                            totalAlerts={totalAlerts}
                            percentageRead={percentageRead}
                            
                            dates={dates}
                            counts={counts}
                            latestCount={latestCount}
                            vitalSignsDates={vitalSignsDates}
                            vitalSignsPercentages={
                              vitalSignsPercentages
                            }
                            latestVitalSignsPercentage={
                              latestVitalSignsPercentage
                            }
                            
                            
                          />
                        ) : (
                          <Navigate to="/login" replace />
                        )
                      }
                    />

                    {/* Rotas para o usuário ISA */}
                    {isIsaUser && (
                      <>
                        <Route path="/alertas" element={<AlertasPage />} />
                        <Route
                          path="/sinaisvitais"
                          element={<SinaisVitaisPage />}
                        />
                        <Route
                          path="/profissionais"
                          element={<ProfissionaisPage />}
                        />
                        <Route path="/pacientes" element={<PacientesPage />} />
                        <Route
                          path="/caregiver/:caregiverID"
                          element={<CaregiverDetailsPage />}
                        />
                      </>
                    )}

                    {/* Rotas para o usuário Sanii */}
                    {isSaniiUser && (
                      <>
                        <Route
                          path="/relatorios"
                          element={<RelatoriosPage />}
                        />
                        <Route
                          path="/gestaodepresenca"
                          element={<GestaoDePresencaPage />}
                        />
                      </>
                    )}

                    {/* Rota para qualquer outra rota não definida */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </div>
              </div>
            </RequireAuth>
          }
        />
      </Routes>
    </LoadingProvider>
  );
}

// Componente separado para o conteúdo do Dashboard
function DashboardContent(props) {
  const {
    vitalSignsToDisplay,
    alertsToDisplay,
    riscoLabels,
    riscoValues,
    porcentagemCriticoRisco,
    totalAlerts,
    percentageRead,
    dates,
    counts,
    latestCount,
    vitalSignsDates,
    vitalSignsPercentages,
    latestVitalSignsPercentage,
  } = props;

  const { handleSave, selectedAlert, showPopup, setShowPopup, handleCheckboxAction, checkedAlerts } = props;

  const [comment, setComment] = useState('');
  const [action, setAction] = useState('');
  const [resolved, setResolved] = useState(false);

  useEffect(() => {
    setComment(selectedAlert?.comment || '');
    setAction(selectedAlert?.action || '');
    setResolved(selectedAlert?.solved || false);
  }, [selectedAlert]);

  return (
    <div className="dashboard-content">
      {showPopup && selectedAlert && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Alerta</h2>

            <div className="modal-input-group">
            <div className="modal-input">
              <label>Paciente:</label>
              <input
                type="text"
                value={selectedAlert?.patientName || ''}
                readOnly
              />
            </div>
              <div className="modal-input">
                <label>Profissional:</label>
                <input
                  type="text"
                  value={selectedAlert?.caregiverName || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="modal-input">
              <label>Motivo do Alerta:</label>
              <input
                type="text"
                value={
                  selectedAlert?.alertReasons?.join(', ') || ''
                }
                readOnly
              />
            </div>

            <h2>O que fazer?</h2>
            <div className="modal-input">
              <label className="resolved-label">
                Marcar como resolvido
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={resolved}
                    onChange={(e) => setResolved(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>
              </label>
            </div>

            <div className="modal-input">
              <label>Ação</label>
              <select
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="discutir com time">
                  Discutir com time
                </option>
                <option value="discutir com profissional">
                  Discutir com profissional
                </option>
                <option value="deixar observação">
                  Deixar observação
                </option>
              </select>
            </div>

            <div className="modal-input">
              <label>Comentário</label>
              <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Digite aqui"
            ></textarea>
            </div>

            <div className="modal-buttons">
              <button
                onClick={() => setShowPopup(false)}
                className="cancel-button"
              >
                Cancelar
              </button>
              <button
              onClick={() => handleSave(comment, action, resolved)}
              className="submit-button"
            >
              Salvar
            </button>
            </div>
          </div>
        </div>
      )}
      <div className="top-cards">
        {/* Card 1: Alertas em Aberto */}
        <div className="card">
          <h2 className="card-title">
            <FaExclamationTriangle className="alert-icon" />
            Alertas em Aberto
          </h2>
          <div className="card-content">
            <div className="alertas-numero">{totalAlerts}</div>
            <div className="alertas-barra">
              <div className="progress-container">
                <div
                  className="progress-bar"
                  style={{ width: `${percentageRead}%` }}
                >
                  {Math.round(percentageRead)}%
                </div>
              </div>
              <p className="alertas-text">
                % Resposta aos Alertas mais recentes em aberto
              </p>
            </div>
          </div>
        </div>

        {/* Card 2: Classificação de Risco */}
        <div className="card">
          <h2 className="card-title">
            <AiFillAlert className="alert-icon" />
            Classificação de Risco
          </h2>
          <div className="card-content">
            <p className="risco-alto-text">
              {porcentagemCriticoRisco.toFixed(1)}% Risco Crítico
            </p>
            <div className="doughnut-chart">
              <Plot
                data={[
                  {
                    values: riscoValues,
                    labels: riscoLabels,
                    type: 'pie',
                    hole: 0.7,
                    marker: {
                      colors: [
                        '#ffc107',
                        '#dc3545',
                        '#e2772a',
                        '#28a745',
                      ],
                    },
                    textinfo: 'percent',
                    hoverinfo: 'label+percent',
                    textposition: 'inside',
                    insidetextfont: {
                      color: '#ffffff',
                    },
                  },
                ]}
                layout={{
                  annotations: [
                    {
                      font: {
                        size: 12,
                      },
                      showarrow: false,
                      text: 'pacientes',
                      x: 0.5,
                      y: 0.5,
                      xanchor: 'center',
                      yanchor: 'middle',
                    },
                  ],
                  showlegend: false,
                  margin: { t: 0, b: 0, l: 0, r: 0 },
                  paper_bgcolor: 'rgba(0,0,0,0)',
                  plot_bgcolor: 'rgba(0,0,0,0)',
                  autosize: true,
                  width: 150,
                  height: 150,
                }}
                config={{ displayModeBar: false }}
              />
            </div>
            <div className="risk-legend-horizontal">
              <span className="legend-item-horizontal">
                <span className="legend-color low"></span>
                Baixo
              </span>
              <span className="legend-item-horizontal">
                <span className="legend-color moderate"></span>
                Moderado
              </span>
              <span className="legend-item-horizontal">
                <span className="legend-color high"></span>
                Alto
              </span>
              <span className="legend-item-horizontal">
                <span className="legend-color critic"></span>
                Crítico
              </span>
            </div>
          </div>
        </div>

        {/* Card 3: Plantões Iniciados Hoje */}
        <div className="card">
          <h2 className="card-title">
            <FaCheck className="alert-icon" />
            Plantões Iniciados Hoje
          </h2>
          <div className="card-content">
            <p className="plantoes-text">{latestCount} Plantões</p>
            <div className="plantoes-chart">
              <Plot
                data={[
                  {
                    x: dates,
                    y: counts,
                    type: 'bar',
                    marker: {
                      color: counts.map(() => 'rgba(54, 162, 235, 1)'),
                      line: {
                        width: 1,
                        color: 'darkblue',
                      },
                    },
                    hoverinfo: 'y',
                    text: counts.map(String),
                    textposition: 'outside',
                    cliponaxis: false,
                  },
                ]}
                layout={{
                  xaxis: {
                    tickvals: dates,
                    tickformat: '%d/%m',
                    showgrid: false,
                    tickfont: { size: 8 },
                  },
                  yaxis: {
                    showgrid: false,
                    zeroline: true,
                    showticklabels: false,
                  },
                  margin: { t: 20, b: 30, l: 20, r: 20 },
                  annotations: [
                    {
                      text: 'nº de plantões iniciados por dia',
                      x: 0.5,
                      y: -0.5,
                      showarrow: false,
                      xref: 'paper',
                      yref: 'paper',
                      font: {
                        size: 10,
                      },
                    },
                  ],
                  showlegend: false,
                  autosize: true,
                  height: 110,
                  paper_bgcolor: 'rgba(0,0,0,0)',
                  plot_bgcolor: 'rgba(0,0,0,0)',
                }}
                style={{
                  width: '100%',
                  height: '110px',
                }}
                config={{ displayModeBar: false }}
              />
            </div>
          </div>
        </div>

        {/* Card 4: Aferições de Sinais Vitais */}
        <div className="card">
          <h2 className="card-title">
            <MdChecklist className="alert-icon" />
            Aferições de Sinais Vitais
          </h2>
          <div className="card-content">
            <p className="vitalsigns-text">
              {latestVitalSignsPercentage.toFixed(0)}% Aferições
            </p>
            <div className="vitalsigns-chart">
              <Plot
                data={[
                  {
                    x: vitalSignsDates,
                    y: vitalSignsPercentages,
                    type: 'bar',
                    marker: {
                      color: vitalSignsPercentages.map(() => '#e2772a'),
                      line: {
                        width: 1,
                        color: 'red',
                      },
                    },
                    hoverinfo: 'y',
                    text: vitalSignsPercentages.map(
                      (percentage) => `${percentage.toFixed(0)}%`
                    ),
                    textposition: 'outside',
                    cliponaxis: false,
                  },
                ]}
                layout={{
                  xaxis: {
                    tickvals: vitalSignsDates,
                    tickformat: '%d/%m',
                    showgrid: false,
                    tickfont: { size: 8 },
                  },
                  yaxis: {
                    showgrid: false,
                    zeroline: true,
                    showticklabels: false,
                    range: [0, 100],
                  },
                  margin: { t: 20, b: 30, l: 20, r: 20 },
                  annotations: [
                    {
                      text: '% de aferições realizadas / aferições esperadas por dia',
                      x: 0.5,
                      y: -0.5,
                      showarrow: false,
                      xref: 'paper',
                      yref: 'paper',
                      font: {
                        size: 10,
                      },
                    },
                  ],
                  showlegend: false,
                  autosize: true,
                  height: 110,
                  paper_bgcolor: 'rgba(0,0,0,0)',
                  plot_bgcolor: 'rgba(0,0,0,0)',
                }}
                style={{
                  width: '100%',
                  height: '110px',
                }}
                config={{ displayModeBar: false }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Cards */}
      <div className="bottom-cards">
        {/* Últimas Aferições */}
        <div className="card large-card">
          <h2 className="card-title">Últimas Aferições</h2>
          <div className="card-table">
            <table>
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Horário</th>
                  <th>Paciente</th>
                  <th>Profissional</th>
                  <th>Score</th>
                  <th>Risco</th>
                </tr>
              </thead>
              <tbody>
                {vitalSignsToDisplay.map((sign) => (
                  <tr key={sign.vitalSignsID}>
                    <td>{sign.diaAfericao}</td>
                    <td>{sign.horarioAfericao}</td>
                    <td>{sign.patientName}</td>
                    <td>{sign.caregiverName}</td>
                    <td className={`risco-${sign.risco.toLowerCase()}`}>
                      {sign.score}
                    </td>
                    <td className={`risco-${sign.risco.toLowerCase()}`}>
                      {sign.risco}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Alertas nas Últimas 24 Horas */}
        <div className="card large-card">
          <h2 className="card-title">Últimos Alertas</h2>
          <div className="card-table">
            <table>
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Horário</th>
                  <th>Paciente</th>
                  <th>Profissional</th>
                  <th>Alerta</th>
                  <th>Resolvido?</th>
                </tr>
              </thead>
              <tbody>
                {alertsToDisplay.map((alert) => (
                  <tr key={alert.alertID}>
                    <td>{alert.day}</td>
                    <td>{alert.time}</td>
                    <td>{alert.patientName}</td>
                    <td>{alert.caregiverName}</td>
                    <td>
                      <ul>
                        {alert.alertReasons && alert.alertReasons.length > 0 ? (
                          alert.alertReasons.map((a, index) => (
                            <li key={index}>{a}</li>
                          ))
                        ) : (
                          <li>Nenhum alerta</li>
                        )}
                      </ul>
                    </td>
                    <td
                    className={`checkbox-container ${
                      checkedAlerts[alert.alertID] ? 'checked' : 'unchecked'
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={checkedAlerts[alert.alertID] || false}
                      onChange={() => handleCheckboxAction(alert)}
                    />
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
